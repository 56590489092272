import { faFacebook, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import instagramicon from "../assets/images/instagramicon.svg";
import linkdinicon from "../assets/images/linkedinicon.svg";
import twitter from "../assets/images/twitter.svg";
import pinterest from "../assets/images/pinterest.svg";

function Profileconnect() {

    return (
        <>
            <Col md={12} lg={12}>
                <div className="connect-profile">
                    <h1>Connect a Profile</h1>
                    <h6>Select a Network to connect Profile</h6>
                    <span>If you have several accounts to connect, do't worry-we'll bring you back to screen after each profile is setup</span>
                </div>
                <div className="select-card">
                    <span> in Group</span>
                    <Row>
                        <Col md={4}>
                            <Form.Select>
                                <option>Digital Garage</option>
                                <option value="1">Culture Catalyst</option>
                                <option value="2">Sahl</option>
                                <option value="3">Innovate</option>
                            </Form.Select>
                        </Col>
                        <Col md={8} className="align-center">
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <span><Form.Check aria-label="option 1" />Facebook</span>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <span><Form.Check aria-label="option 1" />Instagram</span>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <span><Form.Check aria-label="option 1" />Twitter</span>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <span><Form.Check aria-label="option 1" />Linkedin</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Col md={11}>
                    <div className="key-list">
                        <ul>
                            <li>
                                <Row>
                                    <Col md={1} sm={2} className="align-key">
                                        <div>
                                            <FontAwesomeIcon className="fb-icon" icon={faFacebook}/>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={10}>
                                        <Row className="align-center">
                                            <Col className="p-0" md={2}><span>Auth Token</span></Col>
                                            <Col className="p-0" md={8}><Form.Control className="mar-left" aria-label="Default" /></Col>
                                        </Row>
                                    </Col>
                                    <Col md={3} sm={6} className="facebook-align">
                                        <Row className="align-center">
                                            <Col className="p-0" md={3}><span>Secret Key</span></Col>
                                            <Col className="p-0" md={8}><Form.Control aria-label="Default" /></Col>
                                        </Row>
                                    </Col>
                                    <Col md={1} sm={6}>
                                        <div className="btn-card">
                                            <div className="add-btn"><Button>Authorize</Button></div>
                                            {/* <div className="remove-btn"><Button>Remove</Button></div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <div className="padding-card">
                                    <Row>
                                        <Col md={1} className="align-key">
                                            <div className="insta-align">
                                                <img className="insta-i" src={instagramicon} />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={2}><span>Auth Token</span></Col>
                                                <Col className="p-0" md={8}><Form.Control className="mar-left" aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={3}><span>Secret Key</span></Col>
                                                <Col className="p-0" md={8}><Form.Control aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={1}>
                                            <div className="btn-card">
                                                <div className="add-btn"><Button>Authorize</Button></div>
                                                {/* <div className="remove-btn"><Button>Remove</Button></div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </li>
                            <li>
                                <div className="padding-card">
                                    <Row>
                                        <Col md={1} className="align-key">
                                            <div className="insta-align">
                                                <img className="insta-i" src={twitter} />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={2}><span>Auth Token</span></Col>
                                                <Col className="p-0" md={8}><Form.Control className="mar-left" aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={3}><span>Secret Key</span></Col>
                                                <Col className="p-0" md={8}><Form.Control aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={1}>
                                            <div className="btn-card">
                                                <div className="add-btn"><Button>Authorize</Button></div>
                                                {/* <div className="remove-btn"><Button>Remove</Button></div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </li>
                            <li>
                                <div className="padding-card">
                                    <Row>
                                        <Col md={1} className="align-key">
                                            <div className="insta-align">
                                                <img className="insta-i" src={linkdinicon} />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={2}><span>Auth Token</span></Col>
                                                <Col className="p-0" md={8}><Form.Control className="mar-left" aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={3}><span>Secret Key</span></Col>
                                                <Col className="p-0" md={8}><Form.Control aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={1}>
                                            <div className="btn-card">
                                                <div className="add-btn"><Button>Authorize</Button></div>
                                                {/* <div className="remove-btn"><Button>Remove</Button></div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </li>
                            <li>
                                <div className="padding-card">
                                    <Row>
                                        <Col md={1} className="align-key">
                                            <div className="insta-align">
                                                <img className="insta-i" src={pinterest} />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={2}><span>Auth Token</span></Col>
                                                <Col className="p-0" md={8}><Form.Control className="mar-left" aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row className="align-center">
                                                <Col className="p-0" md={3}><span>Secret Key</span></Col>
                                                <Col className="p-0" md={8}><Form.Control aria-label="Default" /></Col>
                                            </Row>
                                        </Col>
                                        <Col md={1}>
                                            <div className="btn-card">
                                                <div className="add-btn"><Button>Authorize</Button></div>
                                                {/* <div className="remove-btn"><Button>Remove</Button></div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Col>
        </>
    );
};
export default Profileconnect;