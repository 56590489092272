import React, { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { IoHome, IoIdCard, IoPerson, IoEaselSharp } from "react-icons/io5";
import { MdOutlineWork } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MENU_BEHAVIOUR, MENU_PLACEMENT } from "../../utils/Constants";
import {
  useWindowSize,
  checkBehaviour,
  checkPlacement,
  isDeeplyDiffBehaviourStatus,
  isDeeplyDiffPlacementStatus,
} from "../../utils/config";
import CsLineIcons from "../../components/cs-line-icons/CsLineIcons";
import { useNavigate, Link } from "react-router-dom";
import { RiSettings5Fill } from "react-icons/ri";
// import {
//   userProfile,
//   signInSelector,
//   clearData,
// } from "../../../store/reducer/SignInReducer";
import profile from "../../assets/img/avatar.webp";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import {
  menuChangeCollapseAll,
  menuChangeAttrMenuAnimate,
  menuChangePlacementStatus,
  menuChangeBehaviourStatus,
  menuChangeAttrMobile,
  menuChangeNavClasses,
} from "../../store/menu/menu.action";
import {
  settingSelector,
  getProfileRequest,
} from "../../store/settings/settings.action";
import { clearUserLogout } from "../../store/auth/auth.action";

const DELAY = 80;

const Nav = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    navClasses,
    placement,
    behaviour,
    placementStatus,
    behaviourStatus,
    attrMobile,
    breakpoints,
    useSidebar,
  } = useSelector((state) => state.menu);
  const { profileDetails } = useSelector(settingSelector);

  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);

  // const { signInData, signInLoading, profileData, errorMessage } =
  //   useSelector(signInSelector);
  // const { studentProfileDatas } = useSelector(StudentSelector);
  // console.log(studentProfileDatas?.studentData, "SGFSDHFDJTKU");

  // let studentStatus = studentProfileDatas?.studentData?.hiring_partner_student?.filter(
  //   (ele) => {
  //     return ele.student_acceptance_status == 3;
  //   }
  // );
  // let isShortlisted = studentStatus?.length != 0 ? 3 : 0;
  // studentProfileDatas?.studentData?.hiring_partner_student &&
  // studentProfileDatas?.studentData?.hiring_partner_student[0]
  //   ?.student_acceptance_status;

  const mouseActionTimer = useRef(null);
  const { width } = useWindowSize();
  const hideMobileMenu = () => {
    let newNavClasses = {
      ...navClasses,
      "mobile-side-out": true,
      "mobile-side-ready": true,
      "mobile-side-in": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-ready": false,
        "mobile-side-out": false,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-in": true,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
      dispatch(menuChangeAttrMobile(false));
    }, 230);
  };

  const getMenuStatus = useCallback(
    (pBreakpoints, pPlacement, pBehaviour) => {
      if (pBreakpoints) {
        const placementStatusCB = checkPlacement({
          placement: pPlacement,
          breakpoints: pBreakpoints,
        });
        const behaviourStatusCB = checkBehaviour({
          placement: placementStatusCB.placementHtmlData,
          behaviour: pBehaviour,
          breakpoints: pBreakpoints,
        });

        if (isDeeplyDiffPlacementStatus(placementStatusCB, placementStatus)) {
          dispatch(menuChangePlacementStatus(placementStatusCB));
        }
        if (isDeeplyDiffBehaviourStatus(behaviourStatusCB, behaviourStatus)) {
          dispatch(menuChangeBehaviourStatus(behaviourStatusCB));
        }
      }
      // eslint-disable-next-line
    },
    [behaviourStatus, placementStatus, breakpoints]
  );

  useEffect(() => {
    if (width && placement && behaviour && breakpoints) {
      getMenuStatus(breakpoints, placement, behaviour);
    }
    // eslint-disable-next-line
  }, [width, breakpoints, placement, behaviour]);

  useEffect(() => {
    if (navClasses && navClasses["mobile-side-in"]) {
      window.addEventListener("click", hideMobileMenu);
    }
    return () => {
      window.removeEventListener("click", hideMobileMenu);
    };
    // eslint-disable-next-line
  }, [navClasses]);
  // Starts mobile menu opening sequence
  const showMobileMenu = (e) => {
    e.preventDefault();
    dispatch(menuChangeAttrMobile(true));
    let newNavClasses = {
      ...navClasses,
      "mobile-top-out": true,
      "mobile-top-in": false,
      "mobile-top-ready": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-out": false,
        "mobile-side-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-in": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 230);
  };
  // Vertical menu semihidden state showing
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseEnterDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(false));
      dispatch(menuChangeAttrMenuAnimate("show"));
    }
  };

  // Delayed one that hides or shows the menu. It's required to prevent collapse animation getting stucked
  const onMouseEnter = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);

    mouseActionTimer.current = setTimeout(() => {
      onMouseEnterDelay();
    }, DELAY);
  };

  // Vertical menu semihidden state hiding
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseLeaveDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(true));
      dispatch(menuChangeAttrMenuAnimate("hidden"));
    }
  };

  const onMouseLeave = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);
    mouseActionTimer.current = setTimeout(() => {
      onMouseLeaveDelay();
    }, DELAY);
  };

  // useEffect(() => {
  //   let formData = {};
  //   dispatch(userProfile({ formData }));
  // }, []);

  // useEffect(() => {
  //   console.log(profileData, "profileData");
  // }, [profileData]);

  return (
    <div
      id="nav"
      className={classNames("nav-container d-flex", navClasses)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="nav-content d-flex">
        <div className="logo position-relative">
          <Link to={"/home"}>
            <div className="img"></div>
            {/* <h3>Social App</h3> */}
          </Link>
        </div>

        {console.log(role, "ROLES")}
        <div className="language-switch-container">
          <button
            className="btn btn-empty language-button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              localStorage.clear();
              dispatch(clearUserLogout());
              navigate("/login");
            }}
          >
            {profileDetails?.data?.data?.mail_id ? (
              <span className="align-middle">
                {profileDetails.data.data.mail_id}
              </span>
            ) : (
              <span className="align-middle">User</span>
            )}
            <br />
            <CsLineIcons icon="logout" className="me-2" size="17" />
            <span className="align-middle">Logout</span>
          </button>
        </div>
        <div class="user-container d-flex justify-content-center align-items-center">
          <img className="profile w-40" alt="profile" src={profile} />
        </div>
        <div className="menu-container flex-grow-1 os-host os-theme-dark os-host-overflow os-host-overflow-x os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-size-auto-observer observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-content-glue"></div>
          <div className="os-padding">
            <div className="os-viewport os-viewport-native-scrollbars-invisible">
              <div className="os-content">
                <ul id="menu" className="menu show">
                  <li>
                    <Link
                      to={"/home"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <IoHome />
                      <span className="label">Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/post"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <MdOutlineLocalPostOffice />
                      <span className="label">Post</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/settings"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <RiSettings5Fill />
                      <span className="label">Settings</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Nav;
