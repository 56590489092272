import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const composefeed = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/social/feed", postData, Http.getFileAuthToken());
};

export const getUserPosts = (queries) => {
  console.log(queries, "queries of getposts");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/social/get_posts?social_ids=" + queries,
    Http.getAuthToken()
  );
};

export const getPostStatus = (payload) => {
  const { social_ids = "", status } = payload;
  console.log(payload, "getPostStatus of socialId and status");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/social/get_stats?social_ids=" + social_ids ??
      "" + "&status=" + status,
    Http.getAuthToken()
  );
};
