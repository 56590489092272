import { Row, Col, Button } from "react-bootstrap";
import Options from "./Options";
import dgpost from "../assets/images/dgpost.jpg";
import ccpost from "../assets/images/ccpost.jpg";
import iconfacebook from "../assets/images/iconfacebook.svg";
import instagramicon from "../assets/images/instagramicon.svg";
import linkdinicon from "../assets/images/linkedinicon.svg";
import twitter from "../assets/images/twitter.svg";
import {
  faComment,
  faHeart,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HorizontalInfiniteScroll from "./HorizontalInfinite";
import { useState } from "react";
function Post() {
  const [socials, setSocials] = useState();
  return (
    <>
      <Options socials={setSocials} />
      <HorizontalInfiniteScroll />
      {/* <Col md={12}>
        <div className="post-page-row">
          <h4>Facebook Posts of Digital Garage</h4>
          <Row className="scroll-x">
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width btn-center">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width">
                    <Button className="fail-btn">Failed</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 66
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 2
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width">
                    <Button>scheduled</Button>
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 111
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 1
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width">
                    <img className="posted-icon" src={iconfacebook} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={ccpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div className="post-page-row">
          <h4>Tweets of Digital garage</h4>
          <Row className="scroll-x">
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button className="fail-btn">Failed</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 66
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 2
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button>scheduled</Button>
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 111
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 1
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={twitter} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={ccpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div className="post-page-row">
          <h4>Instagram Posts of Digital Garage</h4>
          <Row className="scroll-x">
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="fail-btn">Failed</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 66
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 2
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button>scheduled</Button>
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 111
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 1
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="instagram-i" src={instagramicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={ccpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div className="post-page-row">
          <h4>Linkedin Posts of Digital Garage</h4>
          <Row className="scroll-x">
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width btn-center">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="fail-btn">Failed</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 66
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 2
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width">
                    <Button>scheduled</Button>
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 111
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 1
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={dgpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="postpage-card">
                <div className="post-flex">
                  <div className="icon-width wp-50">
                    <img className="twitter-icon" src={linkdinicon} />
                  </div>
                  <div className="icon-width">
                    <Button className="posted-btn">Posted</Button>{" "}
                  </div>
                </div>
                <Col md={12}>
                  <div className="tag-qoute">
                    <p>
                      {" "}
                      Dive into new opportunities and watch your success soar!
                      📈
                      <span className="tags-c">
                        {" "}
                        #CareerAcceleration #CareerBoost #TechCareers
                      </span>
                    </p>
                  </div>
                </Col>
                <Col className="img-insights">
                  <img className="post-page-img" src={ccpost} />
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faHeart} /> 10
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faComment} /> 5
                    </li>
                  </ul>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Col> */}
    </>
  );
}
export default Post;
