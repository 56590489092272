import { ComposeActionTypes, composeInitialState } from "./compose.model";

const reducer = (state = composeInitialState, action) => {
  switch (action.type) {
    case ComposeActionTypes.GET_STATUS_REQUEST:
      return {
        ...state,
        isComposeLoading: true,
      };
    case ComposeActionTypes.COMPOSE_FEED_REQUEST:
      return {
        ...state,
        isComposeLoading: true,
      };
    case ComposeActionTypes.COMPOSE_FEED_FAILURE:
      var { payload } = action;
      return {
        ...state,
        isComposeLoading: false,
        groupMessage: payload?.data?.message,
      };
    case ComposeActionTypes.COMPOSE_FEED_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        isComposeLoading: false,
        groupMessage: payload?.data?.message,
      };
    case ComposeActionTypes.CLEAR_POST_MESSAGE:
      var { payload } = action;
      return {
        ...state,
        groupMessage: "",
        value: "",
      };

    case ComposeActionTypes.GET_POSTS_REQUEST:
      return {
        ...state,
        getpostsLoading: true,
      };
    case ComposeActionTypes.GET_POSTS_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        getpostsLoading: false,
        userposts: payload?.data?.data,
      };
    case ComposeActionTypes.GET_POSTS_FAILURE:
      var { payload } = action;
      return {
        ...state,
        getpostsLoading: false,
      };
    case ComposeActionTypes.GET_POSTSTATUS_REQUEST:
      return {
        ...state,
        getPostStatusLoading: true,
      };
    case ComposeActionTypes.GET_POSTSTATUS_SUCCESS:
      var { payload } = action;
      console.log(payload, "dfkdjsifhe post status");
      return {
        ...state,
        getPostStatusLoading: false,
        postView: payload?.data?.data,
        postStat: payload?.data?.stats,
      };
    case ComposeActionTypes.GET_POSTSTATUS_FAILURE:
      var { payload } = action;
      return {
        ...state,
        getPostStatusLoading: false,
      };
    default:
      return state;
  }
};

export { reducer as ComposeReducer };
