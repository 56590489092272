import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, CardTitle } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineCloudSync } from "react-icons/md";
import { Tooltip } from "@mui/material";
import { SlNote } from "react-icons/sl";
import { useDispatch } from "react-redux";
import { syncpostRequest } from "../store/settings/settings.action";

const Leftbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const handleSync = () => {
    const payload = {
      page,
      query,
      limit,
    };
    dispatch(syncpostRequest(payload));
  };

  return (
    <div
      className="leftsidebar"
    >
      <Tooltip placement="left-start" title="Compose">
        <Button
          variant="primary"
          onClick={() => navigate("/compose-post")}
          className="rounded-circle p-3 m-2 d-flex justify-content-center align-items-center mt-3"
        >
          <SlNote size={20} />
        </Button>
      </Tooltip>
      <Tooltip placement="left-start" title="Sync">
        <Button
          className="rounded-circle p-3 m-2 d-flex justify-content-center align-items-center mt-3"
          variant="secondary"
          onClick={handleSync}
        >
          <MdOutlineCloudSync size={20} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Leftbar;
