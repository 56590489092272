import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "react-bootstrap";
import dgpost from "../assets/images/dgpost.jpg";
import ccpost from "../assets/images/ccpost.jpg";
import iconfacebook from "../assets/images/iconfacebook.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  settingSelector,
  syncpostRequest,
} from "../store/settings/settings.action";

const HorizontalInfiniteScroll = () => {
  const dispatch = useDispatch();
  const { syncLoad, syncedData } = useSelector(settingSelector);
  const [items, setItems] = useState(Array.from({ length: 10 }));
  const [loading, setLoading] = useState(false);
  const observer = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  console.log(page, "prev ");

  const lastItemRef = useCallback(
    (node) => {
      if (syncLoad) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        console.log(page * limit, syncedData?.length, "prev iii");
        if (entries[0].isIntersecting && limit == syncedData?.length) {
          //   const payload = {
          //     page: page + 1,
          //     query: "",
          //     limit,
          //   };
          //   dispatch(syncpostRequest(payload));
          setPage((prev) => prev + 1);
          setLimit(10 * (page + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    [syncLoad, page, syncedData]
  );

  //   useEffect(() => {
  //     if (!syncLoad) return;
  //     setTimeout(() => {
  //       setItems((prevItems) => [...prevItems, ...Array.from({ length: 10 })]);
  //       setLoading(false);
  //     }, 1000);
  //   }, [syncLoad]);

  useEffect(() => {
    const payload = {
      page,
      query: "",
      limit,
    };
    dispatch(syncpostRequest(payload));
  }, [page, limit]);

  return (
    <div className="scroll-container">
      <div className="scroll-wrapper">
        <Col md={12}>
          <div className="post-page-row">
            <h4>Facebook Posts of Digital Garage</h4>
            <Row className="scroll-x">
              {syncedData?.map((posts, index) => {
                console.log(posts, "post details");
                if (syncedData?.length === index + 1) {
                  return (
                    <div
                      ref={lastItemRef}
                      className="item postpage-card"
                      key={index}
                    >
                      <div className="post-flex">
                        <div className="icon-width">
                          <img className="posted-icon" src={iconfacebook} />
                        </div>
                        <div className="icon-width btn-center">
                          <Button className="posted-btn">Posted</Button>{" "}
                        </div>
                      </div>
                      <Col md={12}>
                        <div className="tag-qoute">
                          <p>{posts?.description}</p>
                        </div>
                      </Col>
                      <Col className="img-insights">
                        <img
                          className="post-page-img"
                          src={posts?.media?.image?.src}
                        />
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faHeart} />{" "}
                            {posts?.like_counts}
                          </li>
                          {/* <li>
                            <FontAwesomeIcon icon={faComment} /> 5
                          </li> */}
                        </ul>
                      </Col>
                    </div>
                  );
                } else {
                  return (
                    <div
                      ref={lastItemRef}
                      className="item postpage-card"
                      key={index}
                    >
                      <div className="post-flex">
                        <div className="icon-width">
                          <img className="posted-icon" src={iconfacebook} />
                        </div>
                        <div className="icon-width btn-center">
                          <Button className="posted-btn">Posted</Button>{" "}
                        </div>
                      </div>
                      <Col md={12}>
                        <div className="tag-qoute">
                          <p>{posts?.description}</p>
                        </div>
                      </Col>
                      <Col className="img-insights">
                        <img
                          className="post-page-img"
                          src={posts?.media?.image?.src}
                        />
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faHeart} />{" "}
                            {posts?.like_counts}
                          </li>
                          {/* <li>
                            <FontAwesomeIcon icon={faComment} /> 5
                          </li> */}
                        </ul>
                      </Col>
                    </div>
                  );
                }
              })}
            </Row>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default HorizontalInfiniteScroll;
