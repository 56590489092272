import { Button, Card, CardBody, Col, Row, CardTitle } from "react-bootstrap";
import { getusersocialsRequest, userSelector } from "../store/user/user.action";
import Buttons from "@mui/material/Button";
import { SlNote } from "react-icons/sl";
// import DateRangePicker from "react-datepicker";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  composeSelector,
  getPostStatusRequest,
  getpostsRequest,
} from "../store/compose/compose.action";
import Options from "./Options";
import { LuUpload } from "react-icons/lu";
import { SlCalender } from "react-icons/sl";
import {
  calculateTimeDifference,
  getKeyByValue,
  postStatus,
  socialIds,
} from "../utils/Constants";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "rsuite/dist/rsuite.css";
import {
  faCalendarDay,
  faComment,
  faDownLong,
  faHeart,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import Leftbar from "./Leftbar";
import {
  clearfbPosts,
  settingSelector,
  syncpostRequest,
} from "../store/settings/settings.action";
import dgpost from "../assets/images/dgpost.jpg";
import instagramicon from "../assets/images/instagramicon.svg";
import linkdinicon from "../assets/images/linkedinicon.svg";
import twitter from "../assets/images/twitter.svg";
import pinterest from "../assets/images/pinterest.svg";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import PostCards from "./PostCards";

const Home = () => {
  //useStates
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageId, setPageId] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [query, setQuery] = useState("");
  const [selectedSocialId, setSeletcedSocialId] = useState([]);

  //useSelector
  const { userAuthedSocials, userSocialLoader } = useSelector(userSelector);
  const { userposts, postStat, postView } = useSelector(composeSelector);
  const { syncLoad, syncedData } = useSelector(settingSelector);
  console.log(postView, "Iam postView");
  console.log(postStat, syncLoad, "Iam postStat");
  console.log(userposts, postStat, "userpostsuserposts");

  //useEffects
  useEffect(() => {
    dispatch(getusersocialsRequest());
    dispatch(getpostsRequest());
  }, []);

  useEffect(() => {
    dispatch(
      getPostStatusRequest({ social_ids: pageId.toString(), status: 1 })
    );
  }, [pageId]);

  useEffect(() => {
    console.log(
      socialIds,
      selectedSocialId,
      getKeyByValue(socialIds, socialIds[2]),
      "socialIdssocialIds"
    );
    if (
      selectedSocialId.includes(Number(getKeyByValue(socialIds, socialIds[2])))
    ) {
      const payload = {
        page,
        query,
        limit,
      };
      dispatch(syncpostRequest(payload));
    } else {
      dispatch(clearfbPosts());
    }
  }, [page, query, limit, selectedSocialId, socialIds]);

  return (
    <>
      <Row>
        <Col lg={10} sm={10} md={10} className="p-0 width-86">
          <Options socials={setSeletcedSocialId} />
          <div className="shedulepost-card">
            <h5> Your Last Activity</h5>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <div className="filter-card-div">
                  <h5>Today's Publishing</h5>
                  <div className="ul-card">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCalendarDay} />{" "}
                        <span>Shedule post</span>
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faDownLong} />
                        <span>Sent Post</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={6} sm={12}>
                <div className="filter-card-div">
                  {syncLoad ? (
                    <>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={syncLoad}
                        // onClick={() => }
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </>
                  ) : (
                    <></>
                  )}
                  <h5>Your Recent posts</h5>
                  <div className="overflow-card">
                    {/* <div className="insta-card-container">
                      <Row>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={instagramicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Instagram Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                                <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, laborum! consectetur adipisicing elit. Modi, laudantium. Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                                
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={instagramicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Instagram Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={instagramicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Instagram Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <div className="insta-card-container">
                      <Row>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={twitter} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Twitter Account</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Twitter account on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={twitter} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Twitter Account</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={twitter} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Twitter Account</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <div className="insta-card-container">
                      <Row>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={linkdinicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Linkdin Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Twitter account on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar!📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={linkdinicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Linkdin Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="instagram-post-card">
                            <div className="profilelogo-time">
                              <Row>
                                <Col md={2} className="p-0 insta-align-center">
                                  <div className="insta-align">
                                    <img className="tweet-i" src={linkdinicon} />
                                  </div>
                                </Col>
                                <Col md={9} className="p-0 insta-align-center">
                                  <h4>Linkdin Page</h4>
                                </Col>
                                <Col md={10}><span className="date-time">Published by Instagram Page on 05/06/24, 11:00 am </span></Col>
                              </Row>

                            </div>
                            <Col md={12}>
                              <div className="tag-qoute">
                              <p>Dive into new opportunities and watch your success soar! 📈 <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span></p>
                              </div>
                            </Col>
                            <Col className="img-insights">
                              <img className="preview-img" src={dgpost} />
                              <ul>
                                <li><FontAwesomeIcon icon={faHeart} /></li>
                                <li><FontAwesomeIcon icon={faComment} /></li>
                                <li><FontAwesomeIcon icon={faRetweet} /></li>
                              </ul>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                  </div>

                  {/* Dynamic data  */}
                  <div className="insta-card-container">
                    <Row>
                      {syncedData?.map((posts, index) => {
                        console.log(posts);
                        return (
                          <>
                            <PostCards
                              posts={posts}
                              icons={faFacebook}
                              name={"Facebook"}
                            />
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={1} lg={1} sm={1} className="p-0">
          <Leftbar></Leftbar>
        </Col>
      </Row>
    </>
  );
};

export default Home;
