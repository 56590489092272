import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { profileSettingValidator } from "../validators/Validators";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  settingSelector,
  getProfileRequest,
  postProfileRequest,
} from "../store/settings/settings.action";

const ProfileSetting = () => {
  //useStates
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    contact_number: "",
    mail_id: "",
  });
  //useSelector
  const { profileLoading, profileDetails } = useSelector(settingSelector);

  const onSubmit = (formData) => {
    console.log(formData, "ProfileData");
    delete formData.mail_id;
    dispatch(postProfileRequest(formData));
  };
  const formik = useFormik({
    initialValues,
    validationSchema: profileSettingValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  useEffect(() => {
    values.mail_id = profileDetails?.data?.data?.mail_id;
    values.company_name = profileDetails?.data?.data?.company_name;
    values.contact_number = profileDetails?.data?.data?.contact_number;
  }, [profileDetails]);
  console.log(errors, "errors");
  console.log(values);
  console.log(initialValues);

  console.log(profileDetails);

  //useEffect
  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);

  return (
    <>
      {/* <Container> */}
      <div>
        <h2 className="text-muted">Profile Settings</h2>
      </div>
      <div>
        <form className={"tooltip-end-bottom p-3"} onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  value={values.company_name}
                  className={errors?.company_name && "is-invalid"}
                  onChange={handleChange}
                />
                {errors.company_name && touched.company_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.company_name}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top ">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  type="text"
                  name="mail_id"
                  placeholder="Email"
                  disabled
                  value={values.mail_id}
                  className={errors?.mail_id && "is-invalid"}
                  onChange={handleChange}
                />
                {errors.mail_id && touched.mail_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.mail_id}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  initialValues
                  type="text"
                  name="contact_number"
                  placeholder="Contact Number"
                  value={values.contact_number}
                  className={errors?.contact_number && "is-invalid"}
                  onChange={handleChange}
                />
                {errors.contact_number && touched.contact_number && (
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_number}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
          </Row>

          <div className="text-end">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={profileLoading}
            >
              Submit
            </LoadingButton>
          </div>
        </form>
      </div>
      {/* </Container> */}
    </>
  );
};

export default ProfileSetting;
