import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { AccountSettingValidator } from "../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { userSelector } from "../store/user/user.action";
import {
  clearGroupMessages,
  getcurrentGrpRequest,
  getsocialRequest,
  getusergroupRequest,
  setCrntgrpRequest,
  usergroupUpdateRequest,
  settingSelector,
  addGroupRequest,
} from "../store/settings/settings.action";
import SkeletonComp from "../layout/Skeleton";

const AccountSettings = () => {
  //useStates
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    group_id: "",
  });
  const [socialIds, setSocialIds] = useState([]);
  const [optionHide, setOptionHide] = useState(false);

  const { group, socialList, groupMessage, current_group, accountSubmit } =
    useSelector(settingSelector);
  const onSubmit = (formData) => {
    formData.socials_ids = `${socialIds}`;
    dispatch(usergroupUpdateRequest(formData));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AccountSettingValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  //useSelector

  const { userSocialLoader } = useSelector(userSelector);

  //  UseEffect Parts

  useEffect(() => {
    if (group) {
      const parent = current_group != null ? current_group : group[0];
      values.group_id = parent?.id;
      var data = parent?.socials_ids?.split(",");
      if (data) {
        data = data.map((ele) => {
          return parseInt(ele);
        });
      }
      setSocialIds(data);
    }
  }, [group, current_group]);

  useEffect(() => {
    if (groupMessage === "Updated Successfully") {
      dispatch(getusergroupRequest());
    }

    if (groupMessage === "Added Succesfully") {
      dispatch(getusergroupRequest());
      dispatch(clearGroupMessages());
    }

    if (
      groupMessage === "Current Group Updated" ||
      groupMessage === "Socials Updated Successfully"
    ) {
      dispatch(getcurrentGrpRequest());
      dispatch(clearGroupMessages());
    }
  }, [groupMessage]);

  // initial hit
  useEffect(() => {
    dispatch(getusergroupRequest());
    dispatch(getsocialRequest());
    dispatch(getcurrentGrpRequest());
  }, []);

  return (
    <>
      {/* <Container> */}
      <div>
        <h2 className="text-muted">Account Settings</h2>
      </div>
      <div>
        <form className={"tooltip-end-bottom p-3"} onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    const id = parseInt(e.target.value);
                    values.group_id = id;
                    dispatch(setCrntgrpRequest(id));
                    var data =
                      group &&
                      group
                        .find((ele) => ele.id == id)
                        ?.socials_ids?.split(",");
                    if (data) {
                      data = data.map((ele) => {
                        return parseInt(ele);
                      });
                    }
                    setSocialIds(data);
                  }}
                  value={values?.group_id}
                  onFocus={() => setOptionHide(true)}
                  onBlur={() => setOptionHide(false)}
                  className={errors.group_id && "is-invalid"}
                >
                  {optionHide ? <></> : <option>SELECT GROUP</option>}

                  {group &&
                    group?.map((groups) => {
                      return (
                        <>
                          <option
                            selected={groups?.id == values.group_id}
                            value={groups?.id}
                          >
                            {groups?.name}
                          </option>
                        </>
                      );
                    })}
                </Form.Control>
                {errors.company_name && touched.company_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.company_name}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
          </Row>
          <hr />
          <div>
            <h5>Socials </h5>
            <Row>
              {userSocialLoader ? (
                <>
                  <SkeletonComp
                    variant="text"
                    height={32}
                    width={"75%"}
                    sx={{ fontSize: "2rem" }}
                    md={{ fontSize: "2rem" }}
                  />
                </>
              ) : (
                socialList?.map((data) => {
                  return (
                    <Col md={3}>
                      <input
                        type="checkbox"
                        name={"socials" + data?.id}
                        id={"socials" + data?.id}
                        checked={socialIds?.includes(data?.id)}
                        onChange={(e) => {
                          const { checked } = e.target;
                          var ids = socialIds ?? [];
                          if (checked) {
                            if (ids.length == 0) {
                              ids.push(data?.id);
                              setSocialIds(ids);
                            } else {
                              setSocialIds((prev) => [...prev, data?.id]);
                            }
                          } else {
                            const datas = ids.filter((ele) => ele != data?.id);
                            setSocialIds(datas.length == 0 ? null : datas);
                          }
                        }}
                      />
                      <label className="px-3" htmlFor={"socials" + data?.id}>
                        {data?.name}
                      </label>
                    </Col>
                  );
                })
              )}
            </Row>
          </div>
          <div className="text-end">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={accountSubmit}
            >
              Submit
            </LoadingButton>
          </div>
        </form>
      </div>
      {/* </Container> */}
    </>
  );
};

export default AccountSettings;
