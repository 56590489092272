export const ComposeActionTypes = {
  COMPOSE_FEED_REQUEST: "@@user/COMPOSE_FEED_REQUEST",
  COMPOSE_FEED_SUCCESS: "@@user/COMPOSE_FEED_SUCCESS",
  COMPOSE_FEED_FAILURE: "@@user/COMPOSE_FEED_FAILURE",

  GET_POSTS_REQUEST: "@@user/GET_POSTS_REQUEST",
  GET_POSTS_SUCCESS: "@@user/GET_POSTS_SUCCESS",
  GET_POSTS_FAILURE: "@@user/GET_POSTS_FAILURE",

  GET_POSTSTATUS_REQUEST: "@@user/GET_POSTSTATUS_REQUEST",
  GET_POSTSTATUS_SUCCESS: "@@user/GET_POSTSTATUS_SUCCESS",
  GET_POSTSTATUS_FAILURE: "@@user/GET_POSTSTATUS_FAILURE",

  CLEAR_POST_MESSAGE: "@@user/CLEAR_POST_MESSAGE",
};

export const composeInitialState = {
  isComposeLoading: false,
  groupMessage: "",
  getpostsLoading: false,
  getPostStatusLoading: false,
  userposts: [],
  postView: [],
  postStat: [],
};
