import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { verifyMailRequest, authSelector } from "../store/auth/auth.action";
import Swal from "sweetalert2";
import { excludedMessages } from "../utils/Constants";

const VerifyMail = () => {
  //useStates
  const dispatch = useDispatch();
  const { verification } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    key: "",
    pass: "",
  });

  //useSelector
  const { groupMessage, isLoading } = useSelector(authSelector);

  //useEffects
  useEffect(() => {
    setUserDetails({
      key: verification.split(":::")[0].replaceAll("--=)", "/"),
      pass: verification.split(":::")[1].replaceAll("--=)", "/"),
    });
  }, [verification]);

  useEffect(() => {
    console.log(groupMessage, excludedMessages, "signup-console");
    if (
      groupMessage ===
      "Mail verified successfully. Now you can login to the site"
    ) {
      console.log("verify page");
      Swal.fire({
        title: groupMessage,
        icon: "success",
      }).then((data) => {
        // window.location=("/login");
        navigate("/login");
      });
    }
  }, [groupMessage]);

  const handleVerification = () => {
    dispatch(verifyMailRequest(userDetails));
    console.log(userDetails, "jdfdfdkjf");
  };

  return (
    <div>
      <Button onClick={handleVerification}>Verify Mail</Button>
    </div>
  );
};

export default VerifyMail;
