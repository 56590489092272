import React from "react";
import { Button, Card, CardBody, Col, Row, CardTitle } from "react-bootstrap";
import Buttons from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getusersocialsRequest, userSelector } from "../store/user/user.action";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import _ from "lodash";
// import DateTimePicker from "react-datepicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Options({ socials }) {
  //useStates
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageId, setPageId] = useState([]);
  const [value, setValue] = useState(dayjs());
  let currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

  //useSelector
  const { userAuthedSocials, userSocialLoader } = useSelector(userSelector);
  console.log(userAuthedSocials, "userAuthedSocials");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilters = () => {
    setPageId(_.map(userAuthedSocials, "social_id"));
  };

  const handleCheckboxChange = (event, socialId) => {
    const { checked } = event.target;
    console.log(event);
    setPageId((prevPageId) => {
      if (checked) {
        return [...prevPageId, socialId];
      } else {
        return prevPageId.filter((id) => id !== socialId);
      }
    });
  };

  useEffect(() => {
    setPageId(_.map(userAuthedSocials, "social_id"));
  }, [userAuthedSocials]);

  useEffect(() => {
    socials(pageId);
  }, [pageId]);

  return (
    <>
      <Col md={4}>
        {/* <div className="date-overflow">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
              <div className="date-pick">
                <Row>
                  <Col md={6}>
                    <div className="date-border">
                      <DateTimePicker
                        label="Start Date"
                        defaultValue={dayjs(currentDate)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <DateTimePicker
                        label="End Date"
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </div> */}
      </Col>
      <Col md={10} sm={10} lg={10} className="p-0">
        <div className="filterdiv-parent">
          <Row>
            <Col lg={3} md={6} sm={6}>
              <div className="post-filter-card">
                {/* <h6>Profiles<FontAwesomeIcon icon={faAngleDown}/> </h6> */}
                <Buttons
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Profiles
                  <span className="text-align-right">
                    {<KeyboardArrowDownIcon />}
                  </span>
                  <br />
                </Buttons>
                <p className="para-padding">Vieweing All</p>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <h6 className="fw-bolder ms-3">Profiles</h6>
                  <hr />
                  {userAuthedSocials?.map((ele) => (
                    <MenuItem
                      key={ele.social_id}
                      sx={{ width: 400, maxWidth: "100%" }}
                    >
                      <>
                        <input
                          type="checkbox"
                          name={ele.social.name}
                          id={ele.social.name}
                          checked={pageId.includes(ele.social_id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, ele.social_id)
                          }
                        />
                        <label className="mx-2" htmlFor={ele.social.name}>
                          {ele.social.name}
                        </label>
                      </>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <div className="post-filter-card">
                <h6>
                  Post Types
                  <FontAwesomeIcon icon={faAngleDown} />{" "}
                </h6>
                <p>Vieweing All</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <div className="post-filter-card">
                <h6>
                  Tags
                  <FontAwesomeIcon icon={faAngleDown} />{" "}
                </h6>
                <p>Vieweing All</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <div className="post-filter-card">
                <h6> </h6>
                <p onClick={clearFilters}>
                  <span className="border-bottom border-primary text-primary border-0 pointer-cursor">
                    Clear All
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default Options;
