import { useEffect, useState, useRef } from "react";
import { Card, CardBody, Col, Row, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getusersocialsRequest, userSelector } from "../store/user/user.action";
import { useFormik } from "formik";
import { excludedMessages } from "../utils/Constants";
import {
  composeSelector,
  composefeedRequest,
  clearPostMessage,
} from "../store/compose/compose.action";

import { DatePicker } from "rsuite";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Loader from "../layout/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import SkeletonComp from "../layout/Skeleton";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Compose = () => {
  //useState
  const dispatch = useDispatch();
  const [pageId, setPageId] = useState({});
  console.log(pageId);
  const file = useRef();
  console.log(file);
  const [scheduledDate, setscheduledDate] = useState(null);

  const [initialValues, setInitialValues] = useState({ content: "" });

  const onSubmit = (formData) => {
    const socials = Object.keys(pageId);

    var payload = socials.map((payloads) => {
      const images = [];
      for (let i = 0; i < file.current.files.length; i++) {
        images.push(file.current.files[i]);
      }
      return {
        content: formData?.content,
        scheduled_time: scheduledDate,
        // image: file?.current?.files,
        image: images,
        social_ids: parseInt(payloads),
        social_page_id: payloads == 2 ? pageId[payloads] : null,
      };
    });
    const payloadKeys = ["content", "scheduled_time", "image", "social_ids"];
    payload = payload.map((ele) => {
      payloadKeys.map((keys) => {
        if (!ele[keys]) delete ele[keys];
      });
      return ele;
    });
    // formData.social_page_id = parseInt(pageId);
    // formData.scheduled_time = scheduledDate;
    // formData.image = File;

    if (scheduledDate) {
      const givenDateTime = dayjs(scheduledDate);
      const twoMinutesLater = dayjs().add(2, "minutes");
      if (givenDateTime.isBefore(twoMinutesLater)) {
        Swal.fire({
          title: `you can't schedule post before or on ${dayjs(
            twoMinutesLater
          ).format("YYYY-MM-DD hh:mm:ss a")}`,
          icon: "warning",
        });
        setscheduledDate(null);
      } else {
        console.log("dispatched");
        dispatch(composefeedRequest(payload));
        resetForm();
        setscheduledDate(null);
        file.current.value = "";
        setPageId({});
      }
    } else {
      dispatch(composefeedRequest(payload));

      resetForm();
      setscheduledDate(null);
      file.current.value = "";
      setPageId({});
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    formik;

  //useSelector
  const { userAuthedSocials, userSocialLoader } = useSelector(userSelector);
  const { isComposeLoading, groupMessage } = useSelector(composeSelector);
  console.log(userAuthedSocials, "userAuthedSocials");

  console.log(isComposeLoading, "testLoading");

  //useEffect
  useEffect(() => {
    dispatch(getusersocialsRequest());
  }, []);

  // const hanldeFile = (e) => {
  //   file.current = e.target.files;
  // };
  useEffect(() => {
    console.log(groupMessage);
    if (!excludedMessages.includes(groupMessage))
      Swal.fire({
        title: groupMessage,
        icon: "success",
      }).then((data) => {
        if (data) {
          dispatch(clearPostMessage());
        }
      });
  }, [groupMessage]);

  const handleDate = (value) => {
    const givenDateTime = dayjs(value);
    const twoMinutesLater = dayjs().add(2, "minutes");

    if (givenDateTime.isBefore(twoMinutesLater)) {
      Swal.fire({
        title: `you can't schedule post before or on ${dayjs(
          twoMinutesLater
        ).format("YYYY-MM-DD hh:mm:ss a")}`,
        icon: "warning",
      });
      setscheduledDate(null);
    } else {
      setscheduledDate(value);
    }
  };

  return (
    <>
      <div className="m-2 mb-0">
        <Row>
          <Col md={8}>
            <Card>
              <CardBody>
                <div>
                  <h1>Post</h1>
                  <hr className="text-muted" />
                  <form
                    className={"tooltip-end-bottom p-3"}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col>
                        <div className="select-profile-grp">
                          <Form.Select>
                            <option>Digital Garage</option>
                            <option value="1">Culture Catalyst</option>
                            <option value="2">Sahl</option>
                            <option value="3">Innovate</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md="12">
                        <label>Content :</label>
                        <div className="mb-3 mt-2 filled   form-group tooltip-end-top">
                          {/* <CsLineIcons icon="user" /> */}
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="content"
                            placeholder="Type Content Here..."
                            value={values.content}
                            // className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.content && touched.content && (
                            <Form.Control.Feedback type="invalid">
                              {errors.content}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <label>Schedule Time :</label>
                        <div className="mb-3 mt-2 filled form-group tooltip-end-top">
                          {/* <Form.Control
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={values.description}
                            onChange={handleChange}
                          /> */}
                          <DatePicker
                            className="date-width"
                            format="dd MMM yyyy hh:mm:ss aa"
                            showMeridian
                            value={scheduledDate}
                            // caretAs={FaCalendar}
                            onOk={handleDate}
                            name="scheduled_time"
                          // onChange={handleDate}
                          />
                          {errors.description && touched.description && (
                            <Form.Control.Feedback type="invalid">
                              {errors.description}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">

                        <Col>
                          <label>Images</label>
                        </Col>


                        <div className="mb-3 mt-2 filled form-group tooltip-end-top">
                          {/* <Form.Control
                            type="file"
                            name="image"
                            placeholder="image"
                            value={values.image}
                            onChange={handleChange}
                          />
                          {errors.image && touched.image && (
                            <Form.Control.Feedback type="invalid">
                              {errors.image}
                            </Form.Control.Feedback>
                          )} */}

                          <input
                            type="file"
                            name="image"
                            id=""
                            ref={file}
                            multiple
                            // onChange={hanldeFile}
                            className="forInputStyle"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <LoadingButton
                        variant="contained"
                        loading={isComposeLoading}
                        type="submit"
                        disabled={Object.keys(pageId)?.length == 0}
                      >
                        post
                      </LoadingButton>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div>
                  <h1>Profiles</h1>
                  <hr className="text-muted" />
                  {userSocialLoader ? (
                    <>
                      <SkeletonComp
                        variant="text"
                        height={32}
                        width={"75%"}
                        sx={{ fontSize: "2rem" }}
                        md={{ fontSize: "2rem" }}
                      />
                      <SkeletonComp
                        variant="text"
                        width={"50%"}
                        sx={{ fontSize: "1rem" }}
                        md={{ fontSize: "1rem" }}
                      />
                    </>
                  ) : (
                    userAuthedSocials?.map((ele) => {
                      return (
                        <>
                          <h4>
                            {ele?.social_pages && ele?.social_pages[0]?.name}
                          </h4>
                          <input
                            type="checkbox"
                            name={ele?.social?.name}
                            id={ele?.social?.name}
                            checked={pageId[ele?.social_id] ?? false}
                            onChange={(event) => {
                              console.log(pageId[ele?.social_id] ?? false);
                              const { checked } = event?.target;
                              console.log(ele, "checkbox ");
                              if (checked) {
                                if (ele?.social_id == 2) {
                                  pageId[ele?.social_id] =
                                    ele?.social_pages[0]?.id;
                                } else {
                                  pageId[ele?.social_id] = true;
                                }
                              } else {
                                delete pageId[ele?.social_id];
                              }
                              setPageId({ ...pageId });
                            }}
                          />
                          <label className="mx-2" htmlFor={ele?.social?.name}>
                            {ele?.social?.name}
                          </label>
                        </>
                      );
                    })
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Compose;
