import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import LoadingButton from "@mui/lab/LoadingButton";
import "../App.css";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Modal,
  Tab,
  Tabs,
  CardBody,
  Container,
} from "react-bootstrap";
import { useFormik } from "formik";
import { AddGroupValidator } from "../validators/Validators";
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupRequest,
  authSocialsRequest,
  clearGroupMessages,
  getfbpagedetailsRequest,
  settingSelector,
} from "../store/settings/settings.action";
import Swal from "sweetalert2";
import ProfileSetting from "./ProfileSetting";
import AccountSettings from "./AccountSettings";
import { excludedMessages } from "../utils/Constants";
import AuthLinkedIn from "./AuthLinkedIn";
import SkeletonComp from "../layout/Skeleton";

const Settings = () => {
  //useStates
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState();
  const [userSocials, setUserSocials] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });

  const onSubmit = (formData) => {
    dispatch(addGroupRequest(formData));
    resetForm();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: AddGroupValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    formik;

  //useSelector
  const {
    groupError,
    groupMessage,
    group,
    current_group,
    socialList,
    groupLoading,
  } = useSelector(settingSelector);

  //useEffect
  useEffect(() => {
    if (groupError != "")
      Swal.fire({
        title: groupError,
        icon: "error",
      }).then((data) => {
        if (data) dispatch(clearGroupMessages());
      });
  }, [groupError]);

  useEffect(() => {
    console.log(groupMessage, "ndjfsdkfhsdjfhb");
    if (!excludedMessages.includes(groupMessage))
      Swal.fire({
        title: groupMessage,
        icon: "success",
      }).then((data) => {
        if (data) {
          if (groupMessage === "Facebook Authorized Successfully") {
            dispatch(getfbpagedetailsRequest());
          }
          dispatch(clearGroupMessages());
          setIsClicked(false);
        }
      });
  }, [groupMessage]);

  useEffect(() => {
    console.log(current_group, socialList, "currrsoc");
    let data = [];
    data = current_group?.socials_ids?.split(",");
    data = data?.map((ele) => {
      return parseInt(ele);
    });
    setUserSocials(data ?? []);
  }, [current_group, socialList]);

  const closeModal = () => {
    setIsClicked(false);
  };

  const handleGroupAdd = () => {
    setIsClicked(!isClicked);
  };

  const responseFacebook = (response) => {
    console.log(response, "callback");
    if (response?.accessToken) {
      const formData = {
        social_access_token: response?.accessToken,
        social_id: 2,
        provided_user_id: response?.id,
        provided_name: response?.name,
      };
      dispatch(authSocialsRequest(formData));
    }
  };

  return (
    <div>
      <div className="text-end mt-2">
        <Button onClick={handleGroupAdd}>Add Group</Button>
      </div>

      <Modal
        show={isClicked}
        id="studentAssign"
        dialogClassName="modal-100w"
        aria-labelledby="studentAssign"
        tabIndex="-1"
        scrollable
        size="xl"
        onExit={closeModal}
        onHide={closeModal}
      >
        <form className={"tooltip-end-bottom p-3"} onSubmit={handleSubmit}>
          <Modal.Title>Add Group</Modal.Title>
          <Row>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top ">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Group Name"
                  value={values.name}
                  // className="is-invalid"
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top ">
                {/* <CsLineIcons icon="user" /> */}
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={values.description}
                  // className="is-invalid"
                  onChange={handleChange}
                />
                {errors.description && touched.description && (
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
          </Row>

          <div className="text-end">
            <LoadingButton
              loading={groupLoading}
              variant="contained"
              type="submit"
            >
              Create Group
            </LoadingButton>
          </div>
        </form>
      </Modal>
      <Container>
        <div className="mt-2">
          <Card>
            <CardBody>
              <Tabs
                defaultActiveKey="account_settings"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey={"account_settings"} title="Account Settings">
                  <div className="mt-2">
                    <AccountSettings />
                  </div>
                  <div className="mt-2">
                    <h3>Authorization</h3>
                    <Card>
                      <CardBody>
                        {socialList?.length > 0 ? (
                          socialList?.map((apps, index) => {
                            switch (apps?.id) {
                              case 1:
                                break;
                              case 2:
                                if (userSocials.includes(apps.id))
                                  return (
                                    <>
                                      <div className="d-flex justify-content-between ">
                                        <h4>{apps.name}</h4>
                                        <FacebookLogin
                                          appId="875917870939850"
                                          textButton="Authorize"
                                          autoLoad={false}
                                          fields="name,email,picture"
                                          scope="public_profile,user_friends,user_actions.books"
                                          callback={responseFacebook}
                                        />
                                      </div>
                                      {index !== socialList.length - 1 && (
                                        <hr />
                                      )}{" "}
                                    </>
                                  );
                              case 3:
                                if (userSocials.includes(apps.id))
                                  return (
                                    <>
                                      <AuthLinkedIn name={apps?.name} />
                                      {index !== socialList.length - 1 && (
                                        <hr />
                                      )}{" "}
                                    </>
                                  );
                            }
                          })
                        ) : (
                          <>
                            <div className="d-flex justify-content-between">
                              <SkeletonComp variant={"text"} width={"300px"} />
                              <SkeletonComp
                                variant={"rounded"}
                                width={"100px"}
                                height={"40px"}
                              />
                            </div>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </Tab>
                <Tab eventKey={"profile_settings"} title="Profile Settings">
                  <div className="mt-2">
                    <ProfileSetting />
                  </div>
                </Tab>
              </Tabs>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Settings;
