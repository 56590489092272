import Skeleton from "@mui/material/Skeleton";

const SkeletonComp = ({ width, height, variant, sm, md }) => {
  return (
    <Skeleton
      variant={variant}
      animation="wave"
      width={width}
      height={height}
      sm={sm}
      md={md}
    />
  );
};

export default SkeletonComp;
