import { all } from "redux-saga/effects";
import { TestSaga } from "./test/test.saga";
import { AuthSaga } from "./auth/auth.saga";
import { SettingSaga } from "./settings/settings.saga";
import { UserSaga } from "./user/user.saga";
import { ComposeSaga } from "./compose/compose.saga";

export default function* rootSaga() {
  yield all([
    TestSaga(),
    AuthSaga(),
    SettingSaga(),
    UserSaga(),
    ComposeSaga()
  ]);
}
