import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import dgpost from "../assets/images/dgpost.jpg";
import instagramicon from "../assets/images/instagramicon.svg";
import linkdinicon from "../assets/images/linkedinicon.svg";
import twitter from "../assets/images/twitter.svg";
import pinterest from "../assets/images/pinterest.svg";

function Newpost() {
    const [images, setImages] = useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };
    return (
        <>
            <section className="newpost-sec">
                <Row>
                    <Col lg={8} md={6} sm={12}>
                        <div className="postimage">
                            <h2>Your Post</h2>
                            <div className="selectcard-newpost">
                                <h6> in Group</h6>
                                <Col lg={6} md={10} sm={12}>
                                    <Form.Select>
                                        <option>Digital Garage</option>
                                        <option value="1">Culture Catalyst</option>
                                        <option value="2">Sahl</option>
                                        <option value="3">Innovate</option>
                                    </Form.Select>
                                </Col>

                            </div>
                            <div className="selectprofile-newpost">
                                <h6> Select Profile</h6>
                                <Col lg={12} md={12} sm={12} className="align-center">
                                    <Row>
                                        <Col lg={3} md={6} sm={6}>
                                            <span><Form.Check aria-label="option 1" />Facebook</span>
                                        </Col>
                                        <Col lg={3} md={6} sm={6}>
                                            <span><Form.Check aria-label="option 1" />Instagram</span>
                                        </Col>
                                        <Col lg={3} md={6} sm={6}>
                                            <span><Form.Check aria-label="option 1" />Twitter</span>
                                        </Col>
                                        <Col lg={3} md={6} sm={6}>
                                            <span><Form.Check aria-label="option 1" />Linkedin</span>
                                        </Col>
                                    </Row>
                                    <div className="check-list-card newpost-cheklist">
                                    </div>
                                </Col>
                            </div>
                            <div className="newpost-content">
                                <Row>
                                    <Col md={12} sm={12} lg={6}>
                                        <h6>Content</h6>
                                        <div>
                                            <Form.Control as="textarea" rows={4}
                                                placeholder="write your msg" />
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6} sm={12}>
                                        <h6 className="res-h6">Attach images</h6>
                                        <div className="imageattach-card" >
                                            <FontAwesomeIcon className="link-iconstyle" icon={faLink} />Attach image
                                        </div>
                                        <div className="addimage-btn">
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={["jpg"]}
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    <>
                                                        <Button style={isDragging ? { color: "red" } : null}
                                                            onClick={onImageUpload}
                                                            {...dragProps}><FontAwesomeIcon className="btn-iconstyle" icon={faPlus} />Add Another image</Button>
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <img className="post-img" src={image.data_url} />
                                                            </div>
                                                        ))}
                                                    </>)}
                                            </ImageUploading>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                            <div className="post-btn">
                                <h6>Posting Options</h6>
                                <Col md={6} className="flex-dis">
                                    <Button className="blue-btn">Post Now</Button>
                                    <Button className="red-btn">Shedule For Later</Button>
                                </Col>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div className="preview">
                            <h2>Preview Your Post</h2>
                            <Col md={12}>
                                <div className="postimg-card">
                                    <Col>
                                        <div className="profilelogo-time">
                                            <Row>
                                                <Col md={2} className="align-key icon-width">
                                                    <FontAwesomeIcon className="fb-icon" icon={faFacebook} />
                                                </Col>
                                                <Col md={10} className="p-0 align-center content-width">
                                                    <h4>Facebook Page</h4>
                                                </Col>
                                                <Col md={12} sm={12}>
                                                    <span className="date-time">Published by Facebook page 05/06/24, 11:00 am </span>
                                                </Col>
                                            </Row>

                                        </div>
                                        <Col md={12}>
                                            <div className="tag-qoute">
                                                <p>Dive into new opportunities and watch your success soar! 📈
                                                    <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span>
                                                </p>
                                            </div>
                                        </Col>

                                    </Col>
                                    <Col>
                                        <img className="preview-img" src={dgpost} />
                                    </Col>
                                </div>
                                <div className="postimg-card">
                                    <Col>
                                        <div className="profilelogo-time">
                                            <Row>
                                                <Col md={2} className="align-key icon-width">
                                                    <div className="insta-align">
                                                        <img className="tweet-i" src={twitter} />
                                                    </div>
                                                </Col>
                                                <Col md={10} className="p-0 align-center content-width">
                                                    <h4>Twitter Account</h4>
                                                </Col>
                                                <Col md={12}>
                                                    <span className="date-time">Published by Twitter 05/06/24, 11:00 am </span>
                                                </Col>
                                            </Row>

                                        </div>
                                        <Col md={12}>
                                            <div className="tag-qoute">
                                                <p>Dive into new opportunities and watch your success soar! 📈
                                                    <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span>
                                                </p>
                                            </div>
                                        </Col>

                                    </Col>
                                    <Col>
                                        <img className="preview-img" src={dgpost} />
                                    </Col>
                                </div>
                                <div className="postimg-card">
                                    <Col>
                                        <div className="profilelogo-time">
                                            <Row>
                                                <Col md={2} className="align-key icon-width">
                                                    <div className="insta-align">
                                                        <img className="tweet-i" src={linkdinicon} />
                                                    </div>
                                                </Col>
                                                <Col md={10} className="p-0 align-center content-width">
                                                    <h4>Linkedin Post</h4>
                                                </Col>
                                                <Col md={12}>
                                                    <span className="date-time">Published by Linkedin 05/06/24, 11:00 am </span>
                                                </Col>
                                            </Row>

                                        </div>
                                        <Col md={12}>
                                            <div className="tag-qoute">
                                                <p>Dive into new opportunities and watch your success soar! 📈
                                                    <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span>
                                                </p>
                                            </div>
                                        </Col>

                                    </Col>
                                    <Col>
                                        <img className="preview-img" src={dgpost} />
                                    </Col>
                                </div>
                                <div className="postimg-card">
                                    <Col>
                                        <div className="profilelogo-time">
                                            <Row>
                                                <Col md={2} className="align-key icon-width">
                                                    <div className="insta-align">
                                                        <img className="ii-gram" src={instagramicon} />
                                                    </div>
                                                </Col>
                                                <Col md={10} className="p-0 insta-align-center content-width">
                                                    <h4>Instagram Page</h4>
                                                </Col>
                                            </Row>

                                        </div>

                                    </Col>
                                    <Col>
                                        <img className="preview-img" src={dgpost} />
                                    </Col>
                                    <Col md={12}>
                                        <div className="tag-qoute">
                                            <p>Dive into new opportunities and watch your success soar! 📈
                                                <span className="tags-c"> #CareerAcceleration #CareerBoost #TechCareers</span>
                                            </p>
                                        </div>
                                        <span className="date-time">1 Hour ago</span>
                                    </Col>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
}; export default Newpost;