import Compose from "../components/Compose";
// import Dummmy from "../components/Dummmy";
import Home from "../components/Home";
import Login from "../components/Login";
import Newpost from "../components/Newpost";
import Post from "../components/Post";
import Profileconnect from "../components/Profileconnect";
import Settings from "../components/Settings";
import Signup from "../components/Signup";
import VerifyMail from "../components/VerifyMail";

const authProtectedRoutes = [
  { path: "/home", component: Home },
  { path: "/post", component: Post },
  { path: "/settings", component: Settings },
  { path: "/compose-post", component: Compose },
  {path : "/profile-connect" , component: Profileconnect},
  {path :"/new-post", component: Newpost },
  // { path: "/compose-post", component: Compose },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
  { path: "/verify_mail/:verification", component: VerifyMail },
];

export { authProtectedRoutes, publicRoutes };
