import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { LinkedInValidator } from "../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import { authSocialsRequest } from "../store/settings/settings.action";
import { useLinkedIn } from "react-linkedin-login-oauth2";
const AuthLinkedIn = ({ name }) => {
  const { groupMessage } = useSelector((state) => state.SettingReducer);
  const dispatch = useDispatch();
  const onSubmit = (formData) => {
    formData.social_id = 3;
    dispatch(authSocialsRequest(formData));
  };
  const [initialValues, setInitialValues] = useState({
    social_access_token: "",
    provided_user_id: "",
  });
  const { linkedInLogin } = useLinkedIn({
    clientId: "86gn6k32luyj4w",
    redirectUri: "https://socialapi.digital-garage.in/api/v1/user_group/authorize_social",
    // oauthUrl:
    //   "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
    onSuccess: (code) => {
      // Change from `data.code` to `code`
      console.log(code);
    },
    // Change from `onFailure` to `onError`
    onError: (error) => {
      console.log(error);
    },
  });
  const formik = useFormik({
    initialValues,
    validationSchema: LinkedInValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    formik;
  useEffect(() => {
    if (groupMessage === "Linked In Authorized Successfully") resetForm();
  }, [groupMessage]);
  return (
    <div>
      {/* <hr /> */}
      <h4>{name}</h4>
      <div>
        <form className={"tooltip-end-bottom p-3 "} onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top ">
                <Form.Control
                  type="text"
                  name="social_access_token"
                  placeholder="Access Token"
                  className={errors?.social_access_token && "is-invalid"}
                  value={values.social_access_token}
                  onChange={handleChange}
                />
                {errors.social_access_token && touched.social_access_token && (
                  <Form.Control.Feedback type="invalid">
                    {errors.social_access_token}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 mt-2 filled form-group tooltip-end-top ">
                <Form.Control
                  type="text"
                  name="provided_user_id"
                  className={errors?.provided_user_id && "is-invalid"}
                  placeholder="Organisation Id"
                  value={values.provided_user_id}
                  onChange={handleChange}
                />
                {errors.provided_user_id && touched.provided_user_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.provided_user_id}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <div className="text-end">
              <Button
                type="submit"
                className="rounded-0 fs-5 p-3"
                style={{ backgroundColor: "#4c69ba" }}
                color="primary"
              >
                AUTHORIZE
              </Button>
            </div>
          </Row>
        </form>
        <Button onClick={linkedInLogin}>Auth</Button>
      </div>
    </div>
  );
};

export default AuthLinkedIn;
