import { action } from "typesafe-actions";
import { ComposeActionTypes } from "./compose.model";
import { useSelector } from "react-redux";

export const composefeedRequest = (props) =>
  action(ComposeActionTypes.COMPOSE_FEED_REQUEST, props);

export const composefeedSuccess = (props) =>
  action(ComposeActionTypes.COMPOSE_FEED_SUCCESS, props);

export const composefeedFailure = (props) =>
  action(ComposeActionTypes.COMPOSE_FEED_FAILURE, props);

export const getpostsRequest = (props) =>
  action(ComposeActionTypes.GET_POSTS_REQUEST, props);

export const getpostsSuccess = (props) =>
  action(ComposeActionTypes.GET_POSTS_SUCCESS, props);

export const getpostsFailure = (props) =>
  action(ComposeActionTypes.GET_POSTS_FAILURE, props);

export const getPostStatusRequest = (props) =>
  action(ComposeActionTypes.GET_POSTSTATUS_REQUEST, props);

export const getPostStatusSuccess = (props) =>
  action(ComposeActionTypes.GET_POSTSTATUS_SUCCESS, props);

export const getPostStatusFailure = (props) =>
  action(ComposeActionTypes.GET_POSTSTATUS_FAILURE, props);

export const clearPostMessage = (err) =>
  action(ComposeActionTypes.CLEAR_POST_MESSAGE, err);

export const composeSelector = (state) => state.ComposeReducer;
