import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { UserActionTypes } from "./user/user.model";
import { AuthReducer } from "./auth/auth.reducer";
import { TestReducer } from "./test/test.reducer";
import { SettingReducer } from "./settings/settings.reducer";
import { UserReducer } from "./user/user.reducer";
import { ComposeReducer } from "./compose/compose.reducer";
import { menu } from "./menu/menu.reducer";
import { AuthActionTypes } from "./auth/auth.model";

const appReducer = combineReducers({
  AuthReducer,
  TestReducer,
  SettingReducer,
  UserReducer,
  ComposeReducer,
  menu,
});

const persistConfig = {
  key: "socialapp",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.CLEAR_USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
