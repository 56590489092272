import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import {
  faCalendarDay,
  faComment,
  faDownLong,
  faHeart,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PostCards = ({ posts, icons, name }) => {
  return (
    <>
      <Col lg={4} md={12} sm={12}>
        <div className="instagram-post-card">
          <div className="profilelogo-time">
            <Row>
              <Col md={2} className="width-8">
                <div className="insta-align">
                  <FontAwesomeIcon className="fb-icon" icon={icons} />
                </div>
              </Col>
              <Col md={9} className="insta-align-center width-50">
                <h4>{name}</h4>
              </Col>
              <Col md={10}>
                <span className="date-time">
                  {/* Published by Instagram Page on 05/06/24,
                                        11:00 am{" "} */}
                  Posted on {dayjs(posts?.created_at).format("DD MMM YYYY")}
                </span>
              </Col>
            </Row>
          </div>
          <Col md={12}>
            <div className="tag-qoute">
              <p>
                {posts?.description}
                {/* <span className="tags-c">
                                      {" "}
                                      #CareerAcceleration #CareerBoost
                                      #TechCareers
                                    </span> */}
              </p>
            </div>
          </Col>
          <Col className="img-insights">
            <img className="preview-img" src={posts?.media?.image?.src} />
            <ul>
              <li>
                <FontAwesomeIcon icon={faHeart} /> {posts?.like_counts}
              </li>
              <li>
                <FontAwesomeIcon icon={faComment} />
              </li>
            </ul>
          </Col>
        </div>
      </Col>
    </>
  );
};

export default PostCards;
