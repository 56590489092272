import { all, call, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { ComposeActionTypes } from "./compose.model";
import { composefeed, getUserPosts, getPostStatus } from "./compose.api";
import {
  composefeedFailure,
  composefeedSuccess,
  getPostStatusFailure,
  getPostStatusSuccess,
  getpostsFailure,
  getpostsSuccess,
} from "./compose.action";

export function* handleComposeFeed(action) {
  try {
    console.log(action, "ksjfhksjfbf");
    const response = yield call(composefeed, action.payload);
    yield put(composefeedSuccess(response));
  } catch (error) {
    yield put(composefeedFailure(error));
  }
}

export function* handleGetPosts(action) {
  try {
    const response = yield call(getUserPosts, action.payload);
    yield put(getpostsSuccess(response));
  } catch (error) {
    yield put(getpostsFailure(error));
  }
}

export function* handleGetPostStatus(action) {
  try {
    console.log(action,'iam from saga console for stat');
    const response = yield call(getPostStatus, action.payload);
    yield put(getPostStatusSuccess(response));
  } catch (error) {
    yield put(getPostStatusFailure(error));
  }
}

export function* ComposeSaga() {
  yield takeEvery(ComposeActionTypes.COMPOSE_FEED_REQUEST, handleComposeFeed);
  yield takeEvery(ComposeActionTypes.GET_POSTS_REQUEST, handleGetPosts);
  yield takeEvery(
    ComposeActionTypes.GET_POSTSTATUS_REQUEST,
    handleGetPostStatus
  );
}
